.card-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
}

.card-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(255, 55, 95, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 21px;
  overflow: hidden;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
}

.card-item-pic {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.card-item-pic::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: var(--primary);
  box-sizing: border-box;
}

.card-item-img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  padding-top: 0;
  padding-bottom: 0;
}

.card-item-img:hover {
  transform: scale(1.1);
}

.card-item-info {
  padding: 15px 20px 15px;
}

.card-item-title {
  color: #ff375f;
  font-size: 25px;
  line-height: 24px;
  padding: 10px;
}

.card-item-text {
  color: #b1b1b1;
  font-size: 19px;
  line-height: 24px;
}

@media only screen and (max-width: 1024px) {
  .card-item {
    margin-bottom: 2rem;
    min-width: 291px;
  }
}
.image-form-container {
  flex-flow: column;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 80%;
  margin: auto;

  .image-form-title {

  }

  .image-form-content {

    padding: 30px 20px;
    display: flex;
    max-width: 65%;
    margin: auto;

    .image-form-col-form {
      padding-left: 30px;
      padding-right: 15px;
    }

    .image-form-col-img {
      padding-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .image-form-img {
        border-radius: 21px;
        object-fit: cover;
        min-width: 391px;
        height: 421px;
      }
    }

    @media only screen and (min-width: 1200px) {
      .image-form-col-form {
        width: 65%;
      }
    }

    @media only screen and (max-width: 1200px) {
      .image-form-col-img {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .image-form-content {
      margin: unset !important;
      max-width: 80%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .image-form-container {
    width: 95%;
    padding-top: 0;
    padding-bottom: 20px;
  }
}
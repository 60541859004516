.slider-container {

  .slider {
    position: relative;
    display: flex;
    align-items: center;

    .slider-icon {
      color: var(--primary);
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 100%;
      position: absolute;
      opacity: 0.4;
      box-shadow: 2px 2px 2px 2px rgb(54 117 136 / 12%);
      cursor: pointer;
    }

    .slider-cta-container {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%,-50%);
      width: 391px;
    }

    .slider-text-container {
      padding-bottom: 40px;
    }

    .slider-title {
      font-size: 82px;
      color: white;
    }

    .slider-subtitle {
      color: white;
      font-size: 40px;
    }

    .slider-cta-button {
      background: var(--primary);
      color: white;
      width: 95%;
      min-height: 40px;
      display: inline-block;
      border-radius: 21px;
      text-align: center;
      text-decoration: none;
      font-size: 1.5rem;
      border: 1px solid white;
      padding: 8px 10px;
    }

    .slider-icon:hover {
      opacity: 1;
    }

    .left-arrow-icon {
      left: 8px;
    }

    .right-arrow-icon {
      right: 8px;
    }

    .slider-image {
      height: 600px;
      width: 100%;
    }

    @media only screen and (max-width: 1096px) {
      .slider-title {
        font-size: 77px;
      }

      .slider-subtitle {
        font-size: 35px;
      }
    }

    @media only screen and (max-width: 700px) {
      .slider-title {
        font-size: 60px;
      }

      .slider-subtitle {
        font-size: 27px;
      }
    }
  }
}

.background-image-form-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .form-background-text-container {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    width: 35%;
  }

  .form-background-image {
    height: 600px;
    width: 100%;
  }

  @media only screen and (max-width: 1096px) {
    .form-background-text-container {
      width: 55%;
    }
  }

  @media only screen and (max-width: 700px) {
    .form-background-text-container {
      width: 75%;
    }
  }
}


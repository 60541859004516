.form-input-text-checkbox {
  color: #b1b1b1;
  margin-right: 8px;

}

.form-input-text-checkbox-info-black {
  color: black;
}

.form-input-text-checkbox-info-white {
  color: white;
  font-size: 23px;
  font-weight: bold;
}


@media only screen and (max-width: 750px) {
  .form-input-text-checkbox-info-white {
    font-size: 21px;
  }
}

.btn{
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: var(--background);
  border-radius: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--medium {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
  background: var(--background);
  color: var(--primary);
  transition: all 0.3s ease-out;
}
.basic-form-error-messages-container {
  color: var(--primary);
  display: flex;
  padding-right: 25px;
}

.basic-form-error-messages-container-collapse {
}

@media only screen and (max-width: 700px) {
  .basic-form-error-messages-container-collapse {
    margin-top: -65px;
  }
}

.basic-form-error-message {
  visibility: unset !important;
}

.basic-form-error-message-hidden {
  visibility: hidden;
}

.form-input {
  width: 91%;
  padding: 8px 20px;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #b1b1b1;
}

.form-input::placeholder {
  color: #b1b1b1;
}

.form-text-area {
  width: 91%;
  padding: 8px 20px;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #b1b1b1;
}

.form-text-area::placeholder {
  color: #b1b1b1;
}

.form-input-div-button {
  background-color: var(--primary);
  color: var(--background);
  border-radius: 21px;
  border: 1px solid var(--primary);

  height: 60px;
  font-size: 20px;
  width: 191px;
  margin: auto;
  cursor: pointer;
}

.form-input-div-button-text {
  padding-top: 15px;
}

.form-input-checkbox-container {
  text-align: left;
  padding: 2px 8px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
}
.how-it-works-container {

  width: 60%;
  margin: auto;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;

  .how-it-works-text-content-title {
    padding-bottom: 21px;
  }

  .how-it-works-text-content-text {
    line-height: 25px;
  }
}